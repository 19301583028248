.types p {
    letter-spacing: 0.05rem;
    color: white;
    margin-bottom: 7px;
    margin-left: 8px;
}

@media (max-width:575px) {
    .types p {
        text-align: center;
    }
}

.betTypes {
    margin-bottom: 16px;
    margin-left: -0.5px;
    padding: 8px;
    text-align: center;
    color: #370082;
    background-color: white;
    border-radius: 13px;
    font-weight: 700;
    border-bottom: 2px solid white;
}

.types {
    border-radius: 16px;
    /* border: 2px solid white; */
    display: flex;
    flex-direction: column;
}