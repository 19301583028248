.date {
    background-color: none;
    height: 40px;
    width: 100%;
    border: 1px solid #c4c4c4;
    padding-left: 8px;
    /* color: #/; */
    cursor: pointer
}

.PhoneInput {
    width: 104%;
    height: 40px;
    border: 1px solid #c4c4c4;
    padding: 8px;
    border-radius: 5px;
}

.border-radius-2 .modal-content {
    border-radius: 5px;
    /* border: none; */
}

.modal-header {
    background-color: #55e6c1;
    color: white;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PhoneInputInput {
    border: none;
    color: #000000;
}

.textInput {
    margin-bottom: 24px;
    background-color: #c4c4c4;
}

.textInput:focus {
    background-color: #ffffff;

}

.ModalFooterContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.ModalFooterSubmit {
    width: 20%;
    background-color: #370082;
    border: none;
    padding: 8px;
    border-radius: 100px;
    color: #fff;
    margin-bottom: 24px
}

.ModalFooterSubmit:hover {
    background-color: #370082;

}

.forgotPassword {
    margin: 0;
    opacity: 0.7;
    font-weight: 600;
}