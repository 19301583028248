.navLink {
    color: black !important;
    font-weight: 500;
}



.navLink2 {
    color: white !important;
    font-weight: 500;
    padding-top: 6px;
}

.upcomingGames {
    color: #55e6c1 !important;
    font-weight: 600;
    padding-top: 6px;

}

.navLink22 {
    color: white !important;
    font-weight: 500;
    margin-right: 32px;
    padding-top: 6px;

}

@media (max-width: 762px) {
    .navLink2 {
        margin-left: 16px;
    }

    .upcomingGames {
        margin-left: 16px;
    }

    .navLink22 {
        margin-left: 16px;
    }
}

.hamburgerButton {
    border: none;
    padding: 8px 12px 0px 12px;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../../assets/images/hamburgerMenu.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}


.sportDropdown {
    color: white;
    border: none;
}

.sportDropdown:focus {
    outline: none;
    box-shadow: none;
}

.navContainer {
    padding: 10px;
    font-size: 16px;
    padding-left: 24px;
    padding-right: 16px;

}

.navWrapper {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:1200px) {
    .navContainer {
        font-size: 14px;
    }
}

.linkContainer {
    text-transform: capitalize;
}


.logo {
    width: 160px;
    height: 30px;
    background-image: url('../../assets/images/punterwinner.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: -8px;
}

.vl {
    width: 2px;
    height: 30px;
    background-color: white;

}