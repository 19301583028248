.carouselItem {
    background-repeat: no-repeat;
    background-size: 100% 60vh;
    background-position: center;
    height: 60vh;
    width: 100%;
}

.carouselContainer {
    margin-top: 8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-inner {
    border-radius: 16px;

}