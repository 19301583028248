.tableHead {
     background: #fafafa;
     border-bottom: 2px solid #c4c4c4;
     color: #370082;

}

.tableCol {
     background-color: gray;
}

.gameTableContainer {
     max-height: 40vh;
     overflow-y: auto;
     padding-right: 0
}

.gameTableContainer::-webkit-scrollbar {
     display: none;
     /* for Chrome, Safari, and Opera */
}