.newsContainer {
    overflow-x: auto;
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 107vh;
}

.mobileNewsContainer {
    overflow-y: hidden;
    display: flex;
    text-align: center;
    flex-direction: row;
    height: 166px;
}

.newsContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.newsTitle {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 1.3px;
    position: absolute;
    bottom: 0px;
    left: 8px;
    color: #fff;
    font-weight: 600;
    text-align: left;
}

.newsDetailsContainer {
    border-radius: 12px;
    margin-bottom: 8px;
    text-align: center;
    position: relative;
    background-color: black;
}

.mobileNewsDetailsContainer {
    border-radius: 12px;
    margin-left: 8px;
    text-align: center;
    position: relative;
    background-color: black;
}

.newsDate {
    font-weight: 600;
    color: #fff;
    font-size: 10px;
    line-height: 1.3;
    /* opacity: 50%; */
    margin-bottom: 0;
    width: 180px;
    position: absolute;
    top: 8px;
    left: -32px;
}

.newsCat {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3;
    color: #fff;
    margin-bottom: 0;
    position: absolute;
    top: 6px;
    right: 4px;
}

.newsImage {
    border-radius: 12px;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
}

.mobileNewsImage {
    border-radius: 12px;
    opacity: 0.5;
    width: 166px;
    height: 166px;
    aspect-ratio: 1/1;
}