.linksContainer {
    color: black;
    margin-top: 32px;
    margin-left: 16px;
    font-weight: 600;
}


.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    height: 40px;
    font-weight: 600;

}

.logo2 {
    margin: 0;
    width: 170px;
    height: 40px;
    background-image: url('../../assets/images/punterwinnerb.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.SocialMediaIcons {
    color: black;
    margin-right: 16px;
    cursor: pointer
}