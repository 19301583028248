@font-face {
    font-family: Raleway;
    src: url(./assets/fonts/Raleway-VariableFont_wght.ttf);
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Raleway';
}


body {
    overflow-x: hidden;
    background-color: #4E00A2;
}

body::-webkit-scrollbar {
    display: none;
}

.ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -24px;
}