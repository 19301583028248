.upcomingTableContainer {
    height: 40vh;
    overflow-y: auto;
}

.upcomingTableContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.tableContainer {
    background-color: #370082;
    border-radius: 16px;
    padding: 16px;
}